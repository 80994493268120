<!-- 酒店端 首页 -->
<template>
  <section class="cont hotel_index">
    <el-row type="flex">
      <el-col class="m-right-30">
        <h6 class="d-i-block">您好，{{ hotelInfo.hotelName }}酒店</h6>
        <span class="font-24 m-left-20">用户名：{{ userInfo.userName }}</span>
        <div class="loginInfo">
          <h6>登录信息</h6>
          <div class="cell">
            <div>上次退出时间：{{ logoutTime }}</div>
            <div>上次退出IP：{{ ipAddress[1] }}</div>
          </div>
          <div class="cell">
            <div>本次登录时间：{{ loginTime }}</div>
            <div>本次登录IP：{{ ipAddress[0] }}</div>
          </div>
        </div>
        <div class="member">
          <div>当前会员数：{{ totalCount }}人</div>
          <div>本日新增会员：{{ dayCount }}人</div>
          <div>昨日新增会员：{{ yesterdayCount }}人</div>
          <div>本月新增会员：{{ monthCount }}人</div>
        </div>
        <div class="chart">
          <el-radio-group v-model="dateType">
            <el-radio-button :label="-1">昨日</el-radio-button>
            <el-radio-button :label="7">七天</el-radio-button>
            <el-radio-button :label="30">30天</el-radio-button>
          </el-radio-group>
          <CurveChart class="box" :xAxis="xAxis" :series="series" ref="chart"></CurveChart>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="helpCenter">
          <h6>帮助中心</h6>
          <div class="cur" v-for="(item, index) in helpCenterList" :key="item.id" @click="gotoPage(item.url)">{{ index + 1 }}.{{ item.title }}</div>
          <el-link :underline="false" type="primary" class="link" @click="help" v-if="total > 6">查看更多>>></el-link>
        </div>
        <div class="spoa">
          <h6>平台客服</h6>
          <div>联系电话：{{ mobile }}</div>
          <div>售后服务：{{ telephone }}</div>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="帮助中心" :visible.sync="helpShow" width="35%">
      <div v-for="(item, index) in helpList" :key="item.id">
        <span class="cur" @click="gotoPage(item.url)">{{ index + 1 }}.{{ item.title }}</span>
        <el-divider></el-divider>
      </div>
      <pagination :current-page="page" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
    </el-dialog>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { urlObj } from '@/api/interface'
  import { dateFactory } from '@/common/js/common'
  import { memberTotal, getLoginInfo, getPage, getCustomerConfiguration } from '@/api/system/hotelHelpCenter'
  export default {
    name: 'hotel_index',
    data() {
      return {
        userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
        
        dateType: -1,        // 日期类型
        helpShow: false,     // 帮助中心弹窗
        helpCenterList: [],  // 帮助列表
        helpList: [],        // 弹窗列表

        loginTime: '',       // 登录时间
        logoutTime: '',      // 退出时间
        ipAddress: [],       // IP地址

        dayCount: '',        // 本日新增
        yesterdayCount: '',  // 昨日新增
        monthCount: '',      // 本月新增
        totalCount: '',      // 总数

        xAxis:[],            // X轴坐标
        series:[],           // 数据

        mobile:'',           // 联系电话
        telephone:'',        // 售后电话

        total: 0,            // 用户列表总条目数
        page: 1,             // 当前页 默认第一页
        limit: 10,           // 每页显示数
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    watch: {
      dateType() {
        this.getMemberList()
      },
    },
    mounted() {
      this.getHotels()
      this.getMemberList()
      this.getLoginInfo()
      this.gethelpCenterList()
      this.getCustomerConfiguration()
    },
    methods: {
      // 获取酒店ID
      getHotels(){
         const url = urlObj.getHotelPage + `?limit=1&page=1`
         const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
         const param = { hotelId: userInfo.extra && userInfo.extra.hotelId || '', companyId: userInfo.storeId || '' }
         this.$axios.post(url, param, 'json').then(res => {
           if (res.success) {
             let hotelList = res.records
             this.$store.dispatch('setHotelInfo', hotelList[0])
             sessionStorage.setItem('hotelInfo', JSON.stringify(hotelList[0]))
           }
         })
       },
      // 获取登录信息
      getLoginInfo() {
        getLoginInfo({ userId: this.userInfo.userId }).then(({ records, success }) => {
          if (success) {
            this.loginTime = records[0].loginTime
            this.logoutTime = records[0].logoutTime
            this.ipAddress = []
            this.ipAddress.push(records[0].ipAddress)
            this.ipAddress.push(records[1].ipAddress)
          }
        })
      },
      // 获取客服信息
      getCustomerConfiguration(){
        getCustomerConfiguration().then(({ records, success })=>{
          if (success) {
            this.mobile = records.mobile
            this.telephone = records.afterSalesTelephone
          }
        })
      },
      // 会员列表
      async getMemberList() {
        let params = {
          hotelId: this.hotelInfo.id,
          flag: true,
        }
        let data = {}
        if (this.dateType > 0) {
          data.bTime = dateFactory.getDistanceToday(-this.dateType)
          data.eTime = dateFactory.getDistanceToday(0)
        }
        await memberTotal(params, data).then(({ records, success }) => {
          if (success) {
            this.xAxis = [];this.series = []
            if (this.dateType > 0) {
              for (let i = 0; i < records.members.total.length; i++) {
                this.xAxis.push(this.dateType > 10 ? records.members.total[i].date.split('-')[2] + '日' : records.members.total[i].date)
                this.series.push(records.members.total[i].totalCount || 0)
              }
            } else {
              for (let i = 0; i < records.memberHotels.length; i++) {
                this.xAxis.push(records.memberHotels[i].date.split(' ')[1] + '时')
                this.series.push(records.memberHotels[i].count || 0)
              }
            }
            this.dayCount = records.dayCount
            this.yesterdayCount = records.yesterdayCount
            this.monthCount = records.monthCount
            this.totalCount = records.total
          }
        })
        // 渲染图表
        this.$refs.chart.drawChart()
      },
      // 帮助中心列表(简化)
      gethelpCenterList() {
        let data = {
          limit: 8,
          page: this.page,
          state:'YES'
        }
        getPage(data).then(({ records, success, total }) => {
          if (success) {
            this.helpCenterList = records
            this.total = total
          }
        })
      },
      // 帮助列表
      help() {
        this.helpShow = true
        let data = {
          limit: this.limit,
          page: this.page,
          state:'YES'
        }
        getPage(data).then(({ records, success, total }) => {
          if (success) {
            this.helpList = records
            this.total = total
          }
        })
      },
      // 路由跳转
      gotoPage(url) {
        window.open(url)
      },
      // 改变每页数
      pageChange(num) {
        this.limit = num
        this.help()
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num
        this.help()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .hotel_index {
    h6 {
      font-size: 24px;
    }
    .loginInfo {
      margin-top: 30px;
      background: #ffffff;
      padding: 20px;
      .cell {
        display: flex;
        margin: 20px 0;
        padding: 0 80px;
        justify-content: space-between;
        flex-wrap: wrap;
        div {
          font-size: 24px;
        }
      }
    }
    .member {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      div {
        width: 20%;
        font-size: 24px;
        background: #ffffff;
        padding: 20px;
      }
    }
    .chart {
      margin-top: 30px;
      background: #ffffff;
      padding: 20px;
      .box {
        width: 100%;
        height: 300px;
      }
    }
    .helpCenter {
      height: 500px;
      background: #ffffff;
      padding: 20px;
      div {
        margin: 20px 0;
        margin-left: 40px;
        font-size: 24px;
        // 溢出隐藏 1行文字
        overflow: hidden;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
      .link {
        width: 100%;
        font-size: 24px;
        text-align: center;
      }
    }
    .spoa {
      margin-top: 30px;
      // height: 175px;
      background: #ffffff;
      padding: 20px;
      div {
        margin: 20px 0;
        margin-left: 40px;
        font-size: 24px;
      }
    }
    .el-divider--horizontal {
      margin: 10px 0;
    }
    .cur {
      cursor: pointer;
    }
  }
</style>
