import request from "@/api/service";
import qs from "qs";

const api = {
   getPage: '/hotel/hotelHelpCenter/getPage',
   add: '/hotel/hotelHelpCenter/add',
   edit: '/hotel/hotelHelpCenter/edit',
   delete: '/hotel/hotelHelpCenter/delete/',
   setCustomerConfiguration: '/hotel/hotelHelpCenter/setCustomerConfiguration', // 设置客户配置
   getCustomerConfiguration: '/hotel/hotelHelpCenter/getCustomerConfiguration', // 获取客户配置\
   // 会员列表
   memberTotal:'/member/memberHotel/memberTotal',
   // 获取登录信息
   getLoginInfo:'/system/loginLog/getLoginLogLimitTwo'
}

export function getPage(params) {
   return request({
      url: api.getPage,
      method: 'POST',
      params
   })
}

export function add(data) {
   return request({
      url: api.add,
      method: 'POST',
      data
   })
}

export function edit(data) {
   return request({
      url: api.edit,
      method: 'POST',
      data
   })
}

export function deleteData(id) {
   return request({
      url: api.delete + id ,
      method: 'GET'
   })
}

export function getCustomerConfiguration() {
   return request({
      url: api.getCustomerConfiguration,
      method: 'POST',
   })
}

export function setCustomerConfiguration(data) {
   return request({
      url: api.setCustomerConfiguration,
      method: 'POST',
      data
   })
}

export function memberTotal(params,data) {
   return request({
      url: api.memberTotal,
      method: 'POST',
      params,
      data:qs.stringify(data)
   })
}

export function getLoginInfo(data) {
   return request({
      url: api.getLoginInfo,
      method: 'POST',
      data
   })
}
